<template>
   <v-card class="mx-auto mb-10">
    <v-card-title class="font-weight-bold">
        Major Donors
    </v-card-title>
    <v-divider />
    <div class="skeleton-list-item w-full" v-if="hourlyReportLoading">
        <v-skeleton-loader  :loading="true" type="image"></v-skeleton-loader>
    </div>
    <div class="ma-0 pa-9 text-center" v-if="!hourlyReportLoading && MajorSummary.length == 0">
        Donation Not Found!.
    </div>
    <v-simple-table class="pa-4" v-if="!hourlyReportLoading  && MajorSummary">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-center">This Hour</th>
            <th class="text-center">Past Hour</th>
            <th class="text-center">2 Hours Ago</th>
          </tr>
        </thead>
        <tbody class="simple-table-borders">
            <tr class="accent lighten-4">
                <td rowspan="2" class="text-center border-right border-left">Once</td>
                <td class="text-right border-right font-weight-bold">No. of donations added to the tally</td>
                <td class="text-center border-right">
                    {{MajorSummary.current && MajorSummary.current.once_count ? MajorSummary.current.once_count : 0}}
                </td>
                <td class="text-center border-right">
                    {{MajorSummary['1_hr_ago'] && MajorSummary['1_hr_ago'].once_count ? MajorSummary['1_hr_ago'].once_count : 0}}
                </td>
                <td class="text-center border-right">
                    {{MajorSummary['2_hr_ago'] && MajorSummary['2_hr_ago'].once_count ? MajorSummary['2_hr_ago'].once_count : 0}}
                </td>
            </tr>
            <tr class="accent lighten-4">
                <td class="text-right border-right font-weight-bold">$ value of donations added to the tally</td>
                <td class="text-center border-right">
                    {{MajorSummary.current && MajorSummary.current.once_amount ? MajorSummary.current.once_amount : 0}}
                </td>
                <td class="text-center border-right">
                    {{MajorSummary['1_hr_ago'] && MajorSummary['1_hr_ago'].once_amount ? MajorSummary['1_hr_ago'].once_amount : 0}}
                </td>
                <td class="text-center border-right">
                    {{MajorSummary['2_hr_ago'] && MajorSummary['2_hr_ago'].once_amount ? MajorSummary['2_hr_ago'].once_amount : 0}}
                </td>
            </tr>
            <tr style="height: 30px"></tr>
            <tr class="accent lighten-4">
                <td rowspan="2" class="text-center border-top border-right border-left">Recurring</td>
                <td class="text-right border-right border-top border-top font-weight-bold">No. of donations added to the tally</td>
                <td class="text-center border-right border-top">
                    {{MajorSummary.current && MajorSummary.current.rec_count ? MajorSummary.current.rec_count : 0}}
                </td>
                <td class="text-center border-right border-top">
                    {{MajorSummary['1_hr_ago'] && MajorSummary['1_hr_ago'].rec_count ? MajorSummary['1_hr_ago'].rec_count : 0}}
                </td>
                <td class="text-center border-top border-right">
                    {{MajorSummary['2_hr_ago'] && MajorSummary['2_hr_ago'].rec_count ? MajorSummary['2_hr_ago'].rec_count : 0}}
                </td>
            </tr>
            <tr class="accent lighten-4">
                <td class="text-right border-right font-weight-bold">$ value of donations received</td>
                <td class="text-center border-right">
                    {{MajorSummary.current && MajorSummary.current.rec_amount ? MajorSummary.current.rec_amount : 0}}
                </td>
                <td class="text-center border-right">
                    {{MajorSummary['1_hr_ago'] && MajorSummary['1_hr_ago'].rec_amount ? MajorSummary['1_hr_ago'].rec_amount : 0}}
                </td>
                <td class="text-center border-right">
                    {{MajorSummary['2_hr_ago'] && MajorSummary['2_hr_ago'].rec_amount ? MajorSummary['2_hr_ago'].rec_amount : 0}}
                </td>
            </tr>
            <tr style="height: 30px"></tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of Incentive Donations</td>
                <td class="text-center border-right border-none">
                    {{MajorSummary.current && MajorSummary.current.incentive_donation ? MajorSummary.current.incentive_donation : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{MajorSummary['1_hr_ago'] && MajorSummary['1_hr_ago'].incentive_donation ? MajorSummary['1_hr_ago'].incentive_donation : 0}}
                </td>
                <td class="text-center border-none">
                    {{MajorSummary['2_hr_ago'] && MajorSummary['2_hr_ago'].incentive_donation ? MajorSummary['2_hr_ago'].incentive_donation : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of new Donors</td>
                <td class="text-center border-right border-none">
                    {{MajorSummary.current && MajorSummary.current.new_donors ? MajorSummary.current.new_donors : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{MajorSummary['1_hr_ago'] && MajorSummary['1_hr_ago'].new_donors ? MajorSummary['1_hr_ago'].new_donors : 0}}
                </td>
                <td class="text-center border-none">
                    {{MajorSummary['2_hr_ago'] && MajorSummary['2_hr_ago'].new_donors ? MajorSummary['2_hr_ago'].new_donors : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of Incentives</td>
                <td class="text-center border-right border-none">
                    {{MajorSummary.current && MajorSummary.current.incentive_count ? MajorSummary.current.incentive_count : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{MajorSummary['1_hr_ago'] && MajorSummary['1_hr_ago'].incentive_count ? MajorSummary['1_hr_ago'].incentive_count : 0}}
                </td>
                <td class="text-center border-none">
                    {{MajorSummary['2_hr_ago'] && MajorSummary['2_hr_ago'].incentive_count ? MajorSummary['2_hr_ago'].incentive_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No. of donations added the inspiration fund</td>
                <td class="text-center border-right border-none">
                    {{MajorSummary.current && MajorSummary.current.inspiration_count ? MajorSummary.current.inspiration_count : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{MajorSummary['1_hr_ago'] && MajorSummary['1_hr_ago'].inspiration_count ? MajorSummary['1_hr_ago'].inspiration_count : 0}}
                </td>
                <td class="text-center border-none">
                    {{MajorSummary['2_hr_ago'] && MajorSummary['2_hr_ago'].inspiration_count ? MajorSummary['2_hr_ago'].inspiration_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">$ value of donations added to the inspiration fund</td>
                <td class="text-center border-right border-none">
                    {{MajorSummary.current && MajorSummary.current.inspiration_amount ? MajorSummary.current.inspiration_amount : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{MajorSummary['1_hr_ago'] && MajorSummary['1_hr_ago'].inspiration_amount ? MajorSummary['1_hr_ago'].inspiration_amount : 0}}
                </td>
                <td class="text-center border-none">
                    {{MajorSummary['2_hr_ago'] && MajorSummary['2_hr_ago'].inspiration_amount ? MajorSummary['2_hr_ago'].inspiration_amount : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">Total no. of donations</td>
                <td class="text-center border-right border-none">
                    {{MajorSummary.current && MajorSummary.current.donation_count ? MajorSummary.current.donation_count : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{MajorSummary['1_hr_ago'] && MajorSummary['1_hr_ago'].donation_count ? MajorSummary['1_hr_ago'].donation_count : 0}}
                </td>
                <td class="text-center border-none">
                    {{MajorSummary['2_hr_ago'] && MajorSummary['2_hr_ago'].donation_count ? MajorSummary['2_hr_ago'].donation_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">$ Total value of donations</td>
                <td class="text-center border-right border-none">
                    {{MajorSummary.current && MajorSummary.current.donation_amount ? MajorSummary.current.donation_amount : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{MajorSummary['1_hr_ago'] && MajorSummary['1_hr_ago'].donation_amount ? MajorSummary['1_hr_ago'].donation_amount : 0}}
                </td>
                <td class="text-center border-none">
                    {{MajorSummary['2_hr_ago'] && MajorSummary['2_hr_ago'].donation_amount ? MajorSummary['2_hr_ago'].donation_amount : 0}}
                </td>
            </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['hourlyReportLoading', 'MajorSummary'])
  }
}
</script>
